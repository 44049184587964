
import './App.css';
//import {Container,  Typography, Grid, Paper } from '@mui/core';
import LandingPage from './LandingPage';



// Import the functions you need from the SDKs you need


import { getAuth, signInAnonymously } from "firebase/auth";


function App() {



  //const auth = getAuth();
  //Anonymous sign in here
  /*signInAnonymously(auth)
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      // ...
    });*/


  
  return (
    <div className="App">


      <LandingPage/>


      

    </div>
  );
}

export default App;

/**
 * 
 * 
 *       <Container>
        <Typography variant="h3" align="center" gutterBottom>
          Central Title
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          Central Subheader
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Paper>Column 1 (80%)</Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper>Column 2 (10%)</Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper>Column 3 (10%)</Paper>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <Paper>
              <img src="your_image_url_here" alt="about" style={{ maxWidth: '100%' }} />
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Typography variant="body1" style={{ padding: '20px' }}>
                About section text goes here
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
 */