// Initialize Firebase
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAuth } from 'firebase/auth';

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import React, { useEffect, useState } from 'react';
import { Button } from "@mui/material";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {

    apiKey: "AIzaSyCkD3nPn3TVbafIAxYTNkcHOrrVUt5J8n4",
  
    authDomain: "playnow-or-later.firebaseapp.com",
  
    projectId: "playnow-or-later",
  
    storageBucket: "playnow-or-later.appspot.com",
  
    messagingSenderId: "911183319564",
  
    appId: "1:911183319564:web:9182833742b3f4fbb11429"
  
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const auth = getAuth();
//const ui = new firebaseui.auth.AuthUI(auth);


/*
const uiConfig = {
  signInSuccessUrl: './',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  ],
  // Terms of service url.
  tosUrl: './#tos',
  // Privacy policy url.
  privacyPolicyUrl: './#privacy',
};

// The start method will wait until the DOM is loaded.
ui.start('#firebaseuiAuthContainer', uiConfig);
*/

const FirebaseAuthUI = () => {
  const [showSign, setShowSign] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
      // Listen for auth state changes
      const unregisterAuthObserver = auth.onAuthStateChanged(user => {
          setUser(user);
          console.log(user);
      });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
      if (showSign && !user) {
          const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

          const uiConfig = {
              signInSuccessUrl: '/',
              signInOptions: [
                  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                  firebase.auth.EmailAuthProvider.PROVIDER_ID,
              ],
              
              tosUrl: '/',
              privacyPolicyUrl: './#privacy',
              signInFlow: 'popup'
          };
          ui.start('#firebaseuiAuthContainer', uiConfig);
      }
  }, [showSign, user]);

  const handleSignOut = () => {
      auth.signOut();
      window.location.reload();
  };

  return (
      <>
          {user ? (
              <Button onClick={() => handleSignOut()} variant='outlined'>
                  Sign Out
              </Button>
          ) : (
              !showSign ? (
                  <Button onClick={() => setShowSign(true)} variant='outlined'>
                      Sign in to vote
                  </Button>
              ) : (
                  <div id="firebaseuiAuthContainer"></div>
              )
          )}
      </>
  );
};

export {FirebaseAuthUI};

export default db;

