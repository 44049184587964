import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';

export default function Features() {
  return (
    <Container id="features" sx={{ py: { xs: 4, sm: 8 }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography
        variant="h2"
        sx={{
          textAlign: 'center',
          fontSize: 'clamp(3.5rem, 10vw, 4rem)',
          marginBottom: 4,  // spacing below the title
        }}
      >
        About
      </Typography>
      
      <Grid container spacing={6} sx={{ width: '100%', alignItems: 'center' }}> {/* Align items center will vertically center the content */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              height: '100%', // Vertical center for text
            }}
          >
            I have been a gamer for a long time. Recently I find myself struggling to know if I should play this new exciting game in EA,
            or if I should wait for bugs to be ironed out or for that new exciting content, rather than "finish the game" now and then miss out because I won't want to start another playthrough. 
            As I also have a tendency to build things to solve problems I come across, might it be with <Link href="https://personas360.com" target="_blank" rel="noopener">understanding who you are and how you come across to others</Link> or <Link href="https://giftgenie.org" target="_blank" rel="noopener">finding the right gift</Link>, I wanted to offer a better option to people than the customary subreddit post "Should I play X now?". 
            Genuinely hope this will be useful to you, please let me know how I improve it, and I wish you a happy time gaming! 
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              width: 210,
              height: 250, // Adjust the path if necessary, assuming from the public folder
              backgroundSize: 'contain',
              backgroundPosition: 'center', // Center the background image
            }}
          >
            <img
              src={
                '/logo2.png'
              }

              style={{ maxHeight: '100%'}}
              alt="Dev"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}