import * as React from 'react';
import { LinearProgress, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import db, { FirebaseAuthUI } from '../firebase-config';
import {collection, onSnapshot ,getDoc, doc, setDoc, increment } from "firebase/firestore"; 
import { useState } from 'react';
import { useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { Link } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useRef } from 'react';




export default function Hero() {
  /*
  const voteGame = async (gameId, voteType) => {
    const gameRef = firestore.collection("games").doc(gameId);
    
    try {
        await firestore.runTransaction(async (transaction) => {
            const gameDoc = await transaction.get(gameRef);
            if (!gameDoc.exists) {
                throw new Error("Document does not exist!");
            }
            
            const newUpVotes = voteType === "upvote" ? gameDoc.data().upVotes + 1 : gameDoc.data().upVotes;
            const newDownVotes = voteType === "downvote" ? gameDoc.data().downVotes + 1 : gameDoc.data().downVotes;
            
            transaction.update(gameRef, { upVotes: newUpVotes, downVotes: newDownVotes });
        });
        
        console.log("Transaction successfully committed!");
    } catch (error) {
        console.log("Transaction failed: ", error);
    }
  };
  */
  const gamesRef = collection(db, "games");
  const votesRef = collection(db, "users");
  const featuresRef = collection(db, "features");
  const [games, setGames]= useState([]);
  const [votes, setVotes]= useState({});
  const [features, setFeatures]= useState([]);

  const auth = getAuth();
  const user = auth.currentUser;

  // Firebase UI 
 
  const sectionRef = useRef(null);

  // Function to scroll to section
 
  const scrollToSection = () => {
    if (sectionRef.current) {
      const elementTop = sectionRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementTop - (window.innerHeight / 2) + (sectionRef.current.clientHeight / 2);

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  
  
  const ColoredLinearProgress = withStyles({
    root: {
      height: 10,
      borderRadius: 5
    },
    colorPrimary: {
      backgroundColor: '#edf661' // Yellow background
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#33ce0c' // Green bar
    }
  })(LinearProgress); 
  
  function ProgressBar({ value }) {
    return (
      <ColoredLinearProgress variant="determinate" value={value} />
    );
  }
 
  //Now == true, later=false, 
  async function addNow(gameID){
    
    if(user){
      // if there's already a vote for the opposing choice, reverse
      if(votes[gameID] !== undefined && !votes[gameID]){
        await setDoc(doc(gamesRef, gameID.toString()), {
          now:increment(1),
          later: increment(-1),
        },{merge:true});
      }else{
        await setDoc(doc(gamesRef, gameID.toString()), {
          now:increment(1),
        },{merge:true});
      }      
      //const newVotes = [...votes, { gameID: true }];
      //remember the vote of the user
      const newVotes = {...votes, [gameID]:true};
      setVotes(newVotes);
      await setDoc(doc(votesRef, user.uid), {votes:newVotes},{merge:true});
    }
  }

  async function addLater(gameID){
    if(user){
      if(votes[gameID]){
        await setDoc(doc(gamesRef, gameID.toString()), {
          now:increment(-1),
          later: increment(1),
        },{merge:true});
      }else{
        await setDoc(doc(gamesRef, gameID.toString()), {
          later:increment(1),
        },{merge:true});
      }
      //const newVotes = [...votes, { gameID: true }];
      const newVotes = {...votes, [gameID]:false};
      setVotes(newVotes);
      await setDoc(doc(votesRef, user.uid), {votes:newVotes},{merge:true});
    }
    
  }
  async function addYes(gameID){
    if(user){
      if(votes[gameID] !== undefined && !votes[gameID]){
        await setDoc(doc(featuresRef, gameID.toString()), {
          now:increment(1),
          later: increment(-1),
        },{merge:true});
      }else{
        await setDoc(doc(featuresRef, gameID.toString()), {
          now:increment(1),
        },{merge:true});
      }
      //const newVotes = [...votes, { gameID: true }];
      const newVotes = {...votes, [gameID]:true};
      setVotes(newVotes);
      await setDoc(doc(votesRef, user.uid), {votes:newVotes},{merge:true});
    }
  }

  async function addNo(gameID){
    if(user){
      if(votes[gameID]){
        await setDoc(doc(featuresRef, gameID.toString()), {
          now:increment(-1),
          later: increment(1),
        },{merge:true});
      }else{
        await setDoc(doc(featuresRef, gameID.toString()), {
          later:increment(1),
        },{merge:true});
      }
      //const newVotes = [...votes, { gameID: true }];
      const newVotes = {...votes, [gameID]:false};
      setVotes(newVotes);
      await setDoc(doc(votesRef, user.uid), {votes:newVotes},{merge:true});
    }
    
  }

  async function getGames (){
    //const querySnapshot = await getDocs(collection(db, "games"));
    //const gamesData = querySnapshot.docs.map(doc => doc.data());
    //setGames(gamesData);
    const unsubscribe = onSnapshot(collection(db, "games"), (querySnapshot) => {
      const gamesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setGames(gamesData);
    });
    return () => unsubscribe();
  }

  async function getFeatures (){

    //const querySnapshot = await getDocs(collection(db, "features"));
    //const featuresData = querySnapshot.docs.map(doc => doc.data());
    //setFeatures(featuresData);

    const unsubscribe = onSnapshot(collection(db, "features"), (querySnapshot) => {
      const featuresData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFeatures(featuresData);
    });

    // Detach listener when component unmounts
    return () => unsubscribe();
  }

  /*
  async function addNewGames (){
    const gamesToAdd = [
      //{ id: '11', name: 'I want to see many more games here', now: 0, later: 0 },
   
      //{ id: '2325290', name: 'Sky: Children of the Light', now: 0, later: 0 },
      { id: '2399830', link:'https://store.steampowered.com/app/2399830/ARK_Survival_Ascended/' ,  name: 'ARK_Survival_Ascended', now: 0, later: 0 },
      { id: '1371980', link:'https://store.steampowered.com/app/1371980/No_Rest_for_the_Wicked/' ,  name: 'No Rest for the Wicked', now: 0, later: 0 },
      { id: '1331550', link:'https://store.steampowered.com/app/1331550/Big_Ambitions/' ,  name: 'Big ambitions', now: 0, later: 0 },
      { id: '1676840', link:'https://store.steampowered.com/app/1676840/For_The_King_II/' ,  name: 'For the King 2', now: 0, later: 0 },
      { id: '2670630', link:'https://store.steampowered.com/app/2670630/Supermarket_Simulator/' ,  name: 'Supermarket Simulator', now: 0, later: 0 },
      { id: '526870', link:'https://store.steampowered.com/app/526870/Satisfactory/' ,  name: 'Satisfactory', now: 0, later: 0 },
      { id: '1366540', link:'https://store.steampowered.com/app/1366540/Dyson_Sphere_Program/' ,  name: 'Dyson sphere program', now: 0, later: 0 },
      { id: '261550', link:'https://store.steampowered.com/app/261550/Mount__Blade_II_Bannerlord/' ,  name: 'Mount and Blade 2: Bannerlord', now: 0, later: 0 },
      { id: '892970', link:'https://store.steampowered.com/app/892970/Valheim/' ,  name: 'Valheim', now: 0, later: 0 },
      { id: '108600', link:'https://store.steampowered.com/app/108600/Project_Zomboid/' ,  name: 'Project Zomboid', now: 0, later: 0 },
      { id: '251570', link:'https://store.steampowered.com/app/251570/7_Days_to_Die/' ,  name: '7 days to die', now: 0, later: 0 },
      { id: '2080690', link:'https://store.steampowered.com/app/2080690/Sunkenland/' ,  name: 'Sunkenland', now: 0, later: 0 },
      { id: '1928980', link:'https://store.steampowered.com/app/1928980/Nightingale/' ,  name: 'Nightingale', now: 0, later: 0 },
      { id: '1159690', link:'https://store.steampowered.com/app/1159690/Voidtrain/' ,  name: 'Voidtrain', now: 0, later: 0 },


      // Add more games as needed
    ];
    
    // Add each game to Firestore
    gamesToAdd.forEach(async game => {
      try {
        //await db.collection('games').doc(game.id).set(game);
        await setDoc(doc(gamesRef, game.id),game);
        console.log(`Game '${game.name}' added successfully.`);
      } catch (error) {
        console.error(`Error adding game '${game.name}':`, error);
      }
    });
  }*/

  

  async function getVotes (userId){
    const userUpvotesRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userUpvotesRef);
    //console.log('useruid', userId, docSnap);
    if (docSnap.exists()) {
          console.log("User upvotes:", docSnap.data());
          console.log(docSnap);
          //return docSnap.data();  // Returns the upvote data if exists
          setVotes(docSnap.data().votes)
          
    }

  }
  useEffect(() => { 
    getGames();
    getFeatures(); // Fetch games when component mounts
  }, []);

  useEffect(() => {
    if (user) {
        getVotes(user.uid).then(upvotes => {
            console.log('Upvotes:', upvotes);
        });
    }
  }, [user]);
  //const test = '1623730';
  //console.log(votes[test]);


  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 2, sm: 3 },
        }}
      >
        <Stack marginBottom={8} spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Play now&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              or Play later?
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            A simple solution when dealing with Early access games or games as a service. The idea is to offer a clear community vote if the game is worth playing now, or, for bugs or content reasons, is worth waiting before burning the excitement of your first playthrough. 
            <br/> 
            Note: This is a barebone MVP, if you like the concept, let me know which future features you would like below, and share it around. The more votes, the more reliable. 
          </Typography>
          
        </Stack>
        <div ref={sectionRef} ></div>
        <FirebaseAuthUI/>

        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={5}>
              
            </Grid>
            <Grid item xs={5}>
              
              Already played it? <br/>Cast your vote!
            </Grid>
            <Grid item xs={2}>
              
            </Grid>
          </Grid>
          {games.map(game => (
            <Grid container item xs={12} alignItems="center" key={game.name}>
              <Grid item xs={6}>
                <Paper style={{ padding: '8px' }}><Link href={game.link} target="_blank" rel="noopener">{game.name ? game.name.charAt(0).toUpperCase() + game.name.slice(1) : 'Loading'}</Link> </Paper>
              </Grid>
              <Grid item xs={3}>
                <Button variant='outlined' sx={{ margin: '3px' }} disabled={votes[game.id]} onClick={()=>{if(user){addNow(game.id)}else{scrollToSection()}}}>Play Now</Button>
                <Button variant='outlined' sx={{ margin: '3px' }} disabled={votes[game.id] !== undefined && !votes[game.id]} onClick={()=>{if(user){addLater(game.id)}else{scrollToSection()}}}>Play later</Button>
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: '8px' }}>Now: {game.now} ({(100*game.now/(game.now+game.later+0.000000001)).toFixed(0)}%) - Later:{game.later} ({(100*game.later/(game.now+game.later+0.000000001)).toFixed(0)}%) </Paper>
                <ProgressBar value={(100*game.now/(game.now+game.later+0.000000001)).toFixed(0)} />
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Stack id="features" marginTop={8} spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h2"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Future features
            
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
           Let me know if this is of value to you, and if it is, which features you would like to see coming first!
          </Typography>
          
        </Stack>

        <Grid container spacing={2}>
          {features.map(game => (
            <Grid container item xs={12} alignItems="center" key={game.name}>
              <Grid item xs={7}>
                <Paper style={{ padding: '8px' }}><Typography>{game.name ? game.name.charAt(0).toUpperCase() + game.name.slice(1) : 'Loading'}</Typography></Paper>
              </Grid>
              <Grid item xs={2}>
                <Button  variant='outlined' sx={{ margin: '3px' }}  disabled={votes[game.id]} onClick={()=>{if(user){addYes(game.id)}else{scrollToSection()}}}>Yes</Button>
                <Button  variant='outlined' sx={{ margin: '3px' }}  disabled={votes[game.id] !== undefined && !votes[game.id]} onClick={()=>{if(user){addNo(game.id)}else{scrollToSection()}}}>No</Button>
              </Grid>
              <Grid item xs={3}>
                <Paper style={{ padding: '8px' }}>Yes: {game.now} ({(100*game.now/(game.now+game.later+0.000000001)).toFixed(0)}%) - No:{game.later} ({(100*game.later/(game.now+game.later+0.000000001)).toFixed(0)}%) </Paper>
              </Grid>
            </Grid>
          ))}
        </Grid>
        
       
      </Container>
    </Box>
  );
}

//<Button hidden={true} onClick={()=>addNewGames()}>Play1 now</Button>